<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <strong>
          </strong> Devices
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details

          ></v-text-field>
        </v-card-title>
        <v-btn
            color="blue-grey"
            class="ma-2 white--text"
            @click="getAssetList"
            :disabled="selectedRows.length === 0"
        >
          Asset List
          <v-icon
              right
              dark
          >
            mdi-folder
          </v-icon>
        </v-btn>
        <v-btn
            color="blue-grey"
            class="ma-2 white--text"
            @click="getControlSheets"
            :disabled="selectedRows.length === 0"
        >
          Control Sheets
          <v-icon
              right
              dark
          >
            mdi-folder
          </v-icon>
        </v-btn>
        <v-btn
            color="blue-grey"
            class="ma-2 white--text"
            @click="getOM"
            :disabled="selectedRows.length === 0"
        >
          O&M
          <v-icon
              right
              dark
          >
            mdi-folder
          </v-icon>
        </v-btn>
        <v-btn
            color="blue-grey"
            class="ma-2 white--text"
            @click="archiveSelected"
            :disabled="selectedRows.length === 0"
        >
          Archive
          <v-icon
              right
              dark
          >
            mdi-archive
          </v-icon>
        </v-btn>
        <v-row>
          <v-col v-if="selectedRows.length > 0" cols="3">
            <v-text-field
                v-model="repLabelCustomer"
                label="Customer"
                clearable
            ></v-text-field>
          </v-col>
          <v-col v-if="selectedRows.length > 0" cols="3">
            <v-text-field
                v-model="repLabelSite"
                label="Site Address"
                clearable
            ></v-text-field>
          </v-col>
          <v-col v-if="selectedRows.length > 0" cols="3">
            <v-text-field
                v-model="repLabelDate"
                label="Date"
                clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-col></v-col>
        <v-data-table
            :headers="headers"
            :items="devices"
            item-key="id"
            class="elevation-1 table-one"
            multi-sort
            :search="search"
            v-model="selectedRows"
            show-select
        >
          <template v-slot:item.name="{item}">
            <div class="d-flex align-center">
              <p class="ma-0 font-weight-medium">
                {{ item.name }}
              </p>
            </div>
          </template>
          <template v-slot:item.action="{item}">
            <div class="d-flex">

              <v-btn
                  color="green"
                  dark
                  outlined
                  @click.stop="viewDevice(item.id)">
                Photos
              </v-btn>
              <v-btn
                  color="red"
                  dark
                  outlined
                  @click="delRecord(item.id)">
                Delete
              </v-btn>
              <v-btn
                  color="blue"
                  dark
                  outlined
                  @click="archiveRecord(item)">
                <v-icon
                    dark
                >
                  mdi-archive-plus
                </v-icon>
              </v-btn>


            </div>
          </template>
        </v-data-table>
      </base-card>
    </v-col>

    <ConfirmDialog ref="confirmDelete"/>
  </v-row>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import {saveAs} from 'file-saver';


export default {
  name: "Devices",
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      // url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      // zoom: 18,
      // center: [51.48819, -0.31524],
      // bounds: null,
      // editing: {},
      // sensors: [],
      repLabelCustomer: '',
      repLabelSite: '',
      repLabelDate: '',
      search: '',
      items: [],
      selectedRows: [],
      headers: [
        {
          text: 'Device Type',
          sortable: true,
          value: 'device_type',
          align: 'left',
        },
        {
          text: 'Serial No',
          sortable: true,
          value: 'serial',
          align: 'left',
        },
        {
          text: 'Customer',
          sortable: true,
          value: 'metadata.customerName',
          align: 'left',
        },
        {
          text: 'Site',
          sortable: true,
          value: 'metadata.site',
          align: 'middle',
        },
        {
          text: 'Installer',
          sortable: true,
          value: 'metadata.installerName',
          align: 'middle',
        },
        {
          text: 'Actions',
          value: 'action',
        }
      ],
    }
  },
  computed: {
    ...mapState('devices', {loadingDevices: 'isFindPending'}),
    ...mapGetters('devices', {findDevicesInStore: 'find'}),

    devices() {
      return this.findDevicesInStore({query: {device_status: {$ne: 'stock'}, archived: {$ne: 1}}}).data || [];
    },
    selectedDeviceIds() {
      return this.selectedRows.map(a => a.id);
    }
  },
  methods: {
    ...mapActions('devices', {findDevices: 'find'}),
    deleteItem(id) {
      this.$store.dispatch('devices/remove', id)
    },
    async delRecord(id) {
      if (
          await this.$refs.confirmDelete.open(
              "Confirm",
              "Are you sure you want to delete this record?"
          )
      ) {
        this.deleteItem(id);
      }
    },
    async archiveRecord(item) {
      item.archived = 1;
      item.patch();
    },
    async onInit(promise) {
      // show loading indicator

      try {
        const {capabilities} = await promise

        // successfully initialized
      } catch (error) {
        alert(error.name);
        if (error.name === 'NotAllowedError') {
          // user denied camera access permisson
        } else if (error.name === 'NotFoundError') {
          // no suitable camera device installed
        } else if (error.name === 'NotSupportedError') {
          // page is not served over HTTPS (or localhost)
        } else if (error.name === 'NotReadableError') {
          // maybe camera is already in use
        } else if (error.name === 'OverconstrainedError') {
          // did you requested the front camera although there is none?
        } else if (error.name === 'StreamApiNotSupportedError') {
          // browser seems to be lacking features
        }
      } finally {
        // hide loading indicator
      }
    },
    onDecode(result) {
      this.result = result
      alert(result);
    },
    viewDevice(id) {
      this.$router.push('/app/deviceview/' + id)
    },
    async getAssetList() {
      saveAs(await (await fetch(process.env.VUE_APP_FEATHERS_WS_URL + 'api/reports/assetList?fileName=emissisAssetList&deviceIds=' + this.selectedDeviceIds, {
        method: 'GET',
        headers: {"Authorization": "Bearer " + this.$store.state.auth.accessToken}
      })).blob(), 'coolnomixAssetList.pdf');
    },
    async getOM() {
      saveAs(await (await fetch(process.env.VUE_APP_FEATHERS_WS_URL + 'api/reports/om?' + new URLSearchParams({
        customer: this.repLabelCustomer,
        site: this.repLabelSite,
        date: this.repLabelDate,
        fileName: 'coolnomixOM',
        deviceIds: this.selectedDeviceIds
      }), {
        method: 'GET',
        headers: {"Authorization": "Bearer " + this.$store.state.auth.accessToken}
      })).blob(), 'coolnomixOM.pdf');
    },
    async archiveSelected() {
      for (const deviceId of this.selectedDeviceIds) {
        const {Device} = this.$FeathersVuex.api
        const thisDevice = await Device.get(deviceId)
        thisDevice.archived = 1;
        thisDevice.patch()
      }
    },
    async getControlSheets() {
      saveAs(await (await fetch(process.env.VUE_APP_FEATHERS_WS_URL + 'api/reports/controlSheet?fileName=emissisControlSheet&deviceIds=' + this.selectedDeviceIds, {
        method: 'GET',
        headers: {"Authorization": "Bearer " + this.$store.state.auth.accessToken}
      })).blob(), 'coolnomixControlSheets.pdf');
    }
  },
  mounted() {
    this.findDevices();
    if (localStorage.devicesIndexSearch) {
      this.search = localStorage.devicesIndexSearch;
    }
  },
  watch: {
    search(newSearchTerm) {
      localStorage.devicesIndexSearch = newSearchTerm;
    }
  },
}
</script>

<style scoped>

</style>
